import React, { useState } from 'react';
import TitleCard from "../Cards/TitleCard";
import ModalUpdate from "./modalUpdate";

function DetailLogistbeta({ data }) {
    const sortedData = [...data].sort((a, b) => b.coeff - a.coeff);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedCurator, setSelectedCurator] = useState(null);

    const handleOpenModal = (rowData, curator) => {
        setSelectedData(rowData);
        setSelectedCurator(curator);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedData(null);
        setSelectedCurator(null);
    };

    return (
        <div>
            <TitleCard title={'За текущий месяц'}>
                <table className='lg:table w-full lg:text-sm text-[6px]'>
                    <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Кол-во админов</th>
                            <th>Оборот</th>
                            <th>кол-во заказов</th>
                            <th>коэфф</th>
                            <th>детали</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map(elem => (
                            <tr key={elem._id}>
                                <td className="border border-[#ccc]/30 p-1 text-center lg:text-start ">
                                    {elem.curator}
                                </td>
                                <td className="border border-[#ccc]/30 p-1 text-center lg:text-start ">
                                    {elem.logistLength}
                                </td>
                                <td className="border border-[#ccc]/30 p-1 text-center lg:text-start">
                                    {elem.totalcom}
                                </td>
                                <td className="border border-[#ccc]/30 p-1 text-center lg:text-start ">
                                    {elem.order}
                                </td>
                                <td className="border border-[#ccc]/30 p-1 text-center lg:text-start ">
                                    {`${elem.coeff}%`}
                                </td>
                                <td className="border border-[#ccc]/30 p-1 text-center lg:text-start">
                                    <button
                                        onClick={() => handleOpenModal(elem.detail, elem.curator)}
                                        className="underline">
                                        Узнать больше
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TitleCard>
            {isModalOpen && (
                <ModalUpdate
                    data={selectedData}
                    curator={selectedCurator}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
}

export default DetailLogistbeta;