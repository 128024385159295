import React from 'react'
import PageFullfilment from '../components/page-components'
import Access from '../../protected/access';

export default function Fullfilment1() {

    const requiredRoles = [
        "admin",
        "it.admin",
        "fulfilment.admin",
        "fulfilment1.admin",
        "aziza.full"
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles)) {

        return (
            <div>
                <PageFullfilment link={'1'} />
            </div>
        )
    } else {
        <Access />;
    }
}