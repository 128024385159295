import TitleCard from "../../components/Cards/TitleCard";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setPageTitle } from '../../features/common/headerSlice'
import { NavLink } from "react-router-dom";
import { Button } from "@material-tailwind/react";

export default function FullfilmentPage() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle({ title: "Отдел фулфилмент" }))
    }, [])

    const INITIAL_INTEGRATION_LIST = [
        {
            name: "фулфилмент Liberty", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
            </svg>
            , location: '/fullfilment-page-1'
        }
    ]


    const [integrationList, setIntegrationList] = useState(INITIAL_INTEGRATION_LIST)


    return (

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {
                integrationList.map((i, k) => {
                    return (
                        <TitleCard key={k} title={i.name} topMargin={"mt-2"}>

                            <p className="flex">
                                <div >{i.icon}</div>
                            </p>
                            <div className="mt-6 text-right">
                                <NavLink to={i.location} className="button">
                                    <Button color="red">Войти</Button>
                                </NavLink>
                            </div>

                        </TitleCard>
                    )

                })
            }
        </div>
    )
}