import React, { useEffect } from 'react'
import SearchList from "../../../components/list-otchet/searchList";
import { useSelector, useDispatch } from 'react-redux';
import { fetchLibertyDatas } from '../../../app/slices/data-otchets/libertyDatasSlice';
import { Navigate } from 'react-router-dom'

export default function SearchLibertyList() {

    const datasItog = useSelector((state) => state.libertyDatas);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLibertyDatas());
    }, [dispatch]);

    const requiredRoles = [
        "admin",
        "admin.liberty",
        "zam.liberty",
        "Азиза Жолболдиева ВМ ТЛБ"
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles) || roles.roles.role.includes("ВМ ТЛБ")) {
        return (
            <SearchList datas={datasItog.data} />
        )
    } else {
        <Navigate to="/access" replace />;
    }
}