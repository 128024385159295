import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const managerSlice = createSlice({
    name: 'manager-raiting',
    initialState: {
        datasItog: [],
        datasItoglast: [],
    },
    reducers: {
        fetchDataSuccess: (state, action) => {
            state.datasItog = action.payload;
        },
        fetchDataSuccesslast: (state, action) => {
            state.datasItoglast = action.payload;
        },
    },
});

export const { fetchDataSuccess, fetchDataSuccesslast } = managerSlice.actions;

export const fetchraitingmanager = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/raitingmanager');
            dispatch(fetchDataSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchraitingmanagerlast = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/raitingmanagerlast2');
            dispatch(fetchDataSuccesslast(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export default managerSlice.reducer;
