import LoginPage from './loginPage'

function ExternalPage() {


    return (
        <div className="">
            <LoginPage />

        </div>
    )
}

export default ExternalPage