export const roles_leader = [
    "admin",
    "leader.admin",
    "leader.zam",
];

export const roles_leader_page = [
    "admin",
    "leader.admin",
    "leader.zam",
    "it.admin",
    "leader.topmanager",
    "Альфия Шаршеева ВМ ЛДР"
];