import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const turanlogSlice = createSlice({
    name: 'turanlogist',
    initialState: {
        datasItog: [],
        loadingItog: false,
        error: null,
    },
    reducers: {
        fetchDataRequest: (state) => {
            state.loadingItog = true;
            state.error = null;
        },
        fetchDataSuccess: (state, action) => {
            state.datasItog = action.payload;
            state.loadingItog = false;
            state.error = null;
        },
        fetchDataFailure: (state, action) => {
            state.loadingItog = false;
            state.error = action.payload;
        },
    },
});

export const { fetchDataRequest, fetchDataSuccess, fetchDataFailure } = turanlogSlice.actions;

export const fetchturanLog = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchDataRequest());
            const response = await api.get('/turancalclogist');
            dispatch(fetchDataSuccess(response.data));
        } catch (error) {
            dispatch(fetchDataFailure(error.message));
        }
    };
};

export default turanlogSlice.reducer;
