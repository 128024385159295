import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import liderDatasSlice from './slices/data-otchets/liderdatasSlice'
import simCardSlice from './slices/managers/simCardSlice'
import monacoDatasSlice from './slices/data-otchets/monacodatasSlice'
import turanDatasSlice from './slices/data-otchets/turandatasSlice'
import fenixDatasSlice from './slices/data-otchets/fenixdatasSlice'
import itogSlice from './slices/itogs/allItogSlice'
import simCardLogistSlice from './slices/logist/simCardLogistSlice'
import lidermanagerSlice from './slices/managers/lidermanagerSlice'
import monacomanagerSlice from './slices/managers/monacomanagerSlice'
import turanmanagerSlice from './slices/managers/turanmanagerSlice'
import fenixmanagerSlice from './slices/managers/fenixmanagerSlice'
import liderlogSlice from './slices/logist/liderlogSlice'
import monacologSlice from './slices/logist/monacologSlice'
import turanlogSlice from './slices/logist/turanlogSlice'
import fenixlogSlice from './slices/logist/fenixlogSlice'
import itogsSlice from './slices/itogs/itogsSlice'
import clientsSlice from './slices/itogs/clientsSlice'
import fboxmanagerSlice from './slices/managers/fboxmanagerSlice'
import fboxDatasSlice from './slices/data-otchets/fboxDatasSlice'
import fboxlogSlice from './slices/logist/fboxlogSlice'
import libertymanagerSlice from './slices/managers/libertymanagerSlice'
import libertylogSlice from './slices/logist/libertylogSlice'
import libertyDatasSlice from './slices/data-otchets/libertyDatasSlice'
import managerSlice from './slices/itogs/raitingmanager'
import schetSlice from './slices/schetfactura/schetSlice'
import schetfacturafboxSlice from './slices/schetfactura/fbox'
import schetfacturafenixSlice from './slices/schetfactura/fenix'
import schetfacturamonacoSlice from './slices/schetfactura/monaco'
import schetfacturaturanSlice from './slices/schetfactura/turan'
import schetfacturaleaderSlice from './slices/schetfactura/lider'
import schetfacturalibertySlice from './slices/schetfactura/liberty'
import buyerSlice from './slices/static-buyer/rainting_buyer'
import logistadminSlice from './slices/logist/logistandadmin'
import logistadminsimSlice from './slices/logist/simcardlogistandadmin'
import expencesTwo from './slices/incoming-outging/incoming.js'
import profitOtchetTwo from './slices/incoming-outging/outgoing.js'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  liderDatas: liderDatasSlice,
  monacoDatas: monacoDatasSlice,
  turanDatas: turanDatasSlice,
  fenixDatas: fenixDatasSlice,
  fboxDatas: fboxDatasSlice,
  libertyDatas: libertyDatasSlice,
  simCards: simCardSlice,
  simCardLogs: simCardLogistSlice,
  allItog: itogSlice,
  lidermanager: lidermanagerSlice,
  monacomanager: monacomanagerSlice,
  turanmanager: turanmanagerSlice,
  fenixmanager: fenixmanagerSlice,
  fboxmanager: fboxmanagerSlice,
  libertymanager: libertymanagerSlice,
  liderlogist: liderlogSlice,
  monacologist: monacologSlice,
  turanlogist: turanlogSlice,
  fenixlogist: fenixlogSlice,
  fboxlogist: fboxlogSlice,
  libertylogist: libertylogSlice,
  itogs: itogsSlice,
  clients: clientsSlice,
  raitingmanager: managerSlice,
  schetfactura: schetSlice,
  schetfacturaliberty: schetfacturalibertySlice,
  schetfacturamonaco: schetfacturamonacoSlice,
  schetfacturaturan: schetfacturaturanSlice,
  schetfacturafenix: schetfacturafenixSlice,
  schetfacturafbox: schetfacturafboxSlice,
  schetfacturaleader: schetfacturaleaderSlice,
  buyers: buyerSlice,
  logistadmin: logistadminSlice,
  logistadminsim: logistadminsimSlice,
  expencesTwo,
  profitOtchetTwo
}

export default configureStore({
  reducer: combinedReducer
})