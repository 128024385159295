
function Access() {

    return (
        <div className="hero h-4/5 bg-base-200">
            <div className="hero-content text-accent text-center">
                <div className="max-w-md">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-48 w-48 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <h1 className="lg:text-5xl text-3xl  font-bold">У вас нет прав доступа к данной странице</h1>
                    <h1 className="lg:text-5xl text-3xl  font-bold">Зайдите в аккаунт</h1>
                </div>
            </div>
        </div>
    )
}

export default Access