import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchClients } from '../../../app/slices/itogs/clientsSlice'
import Clients from '../../../components/raitings/clients'
import TitleCard from '../../../components/Cards/TitleCard'

export default function LibertyClients() {

    const dispatch = useDispatch()
    const clients = useSelector((state) => state.clients.dataCLient);

    useEffect(() => {
        dispatch(fetchClients())
    }, [dispatch])

    return (
        <div>
            {
                typeof clients.turan === 'object' ? <div className='lg:text-sm text-[10px]'>
                    <TitleCard title={'Привлеченные клиенты'}>
                        <div className='mb-10'>
                            <Clients datas={clients.liberty} />
                        </div>
                    </TitleCard>
                </div> : 'загрузка...'
            }
        </div>
    )
}
