import { useDispatch, useSelector } from "react-redux"
import TitleCard from "../../../components/Cards/TitleCard"
import { useEffect } from "react"
import { fetchLogistAdmin } from "../../../app/slices/logist/logistandadmin"

function StaticLogistslast() {

    const dispatch = useDispatch()
    const adminlogist = useSelector(state => state.logistadmin.datasItog)
    useEffect(() => {
        dispatch(fetchLogistAdmin())
    }, [dispatch])


    return (
        <TitleCard title={"Рейтинг логистов среди отделов"}>
            <h1 className="mt-5 mb-5">за прошлый месяц</h1>
            <h1 className="lg:text-[11px] text-[9px] mb-2">формула: коефф = (кол-во заказ * оборот)</h1>
            <div className="overflow-x-auto">
                <table className="w-full lg:text-[12px] text-[9px]">
                    <thead>
                        <tr className="bg-[#ccc]/30 dark:bg-black/20">
                            <th className="border p-2">топ</th>
                            <th className="border p-2">имя</th>
                            <th className="border p-2">отдел</th>
                            <th className="border p-2">кол-во заказ</th>
                            <th className="border p-2">коефф</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            adminlogist.logist && adminlogist.logist.map((elem, index) => {
                                const fixedcoeff = (parseFloat(elem.coeff)).toFixed(2)
                                return (
                                    <tr key={elem._id}>
                                        <td className="border p-2">{index + 1}</td>
                                        <td className="border lg:p-3 p-2 w-full">{elem.name}</td>
                                        <td className="border p-2 text-center">{elem.team}</td>
                                        <td className="border p-2 text-center">{elem.order}</td>
                                        <td className="border p-2 text-center">{fixedcoeff}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </TitleCard>
    )
}

export default StaticLogistslast