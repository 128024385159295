import { useDispatch, useSelector } from "react-redux"
import TitleCard from "../../../components/Cards/TitleCard"
import { useEffect } from "react"
import { fetchliderLog } from '../../../app/slices/logist/liderlogSlice'
import { fetchmonacoLog } from '../../../app/slices/logist/monacologSlice'
import { fetchturanLog } from '../../../app/slices/logist/turanlogSlice'
import { fetchfenixLog } from '../../../app/slices/logist/fenixlogSlice'
import { fetchlibertyLog } from '../../../app/slices/logist/libertylogSlice'

function StaticLogist() {

    const dispatch = useDispatch()
    const lider = useSelector(state => state.liderlogist.datasItog)
    const monaco = useSelector(state => state.monacologist.datasItog)
    const turan = useSelector(state => state.turanlogist.datasItog)
    const fenix = useSelector(state => state.fenixlogist.datasItog)
    const liberty = useSelector(state => state.libertylogist.datasItog)

    useEffect(() => {
        dispatch(fetchliderLog())
        dispatch(fetchmonacoLog())
        dispatch(fetchturanLog())
        dispatch(fetchfenixLog())
        dispatch(fetchlibertyLog())
    }, [dispatch])

    const logist = [...lider, ...monaco, ...turan, ...fenix, ...liberty]

    let data = logist.sort((a, b) => {
        const coeffA = parseFloat(a.coeff);
        const coeffB = parseFloat(b.coeff);

        if (coeffA < coeffB) {
            return 1;
        } else if (coeffA > coeffB) {
            return -1;
        } else {
            return 0;
        }
    });

    const filteredData = data.filter((item) => item.curator !== '');

    return (
        <TitleCard title={"Рейтинг старших админов среди отделов"}>
            <h1 className="mt-5 mb-5">за текущий месяц</h1>
            <h1 className="lg:text-[11px] text-[9px] mb-2">формула: коефф = (оборот / кол-во логистов)</h1>
            <div className="overflow-x-auto">
                <table className="w-full lg:text-[12px] text-[9px]">
                    <thead>
                        <tr className="bg-[#ccc]/30 dark:bg-black/20">
                            <th className="border p-2">топ</th>
                            <th className="border p-2">имя</th>
                            <th className="border p-2">кол-во админов</th>
                            <th className="border p-2">коефф</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.map((item, index) =>
                                <tr>
                                    <td className="border p-2">{index + 1}</td>
                                    <td className="border lg:p-3 p-2 w-full">{item.curator}</td>
                                    <td className="border p-2 text-center">{item.logistLength}</td>
                                    <td className="border p-2 text-center">{item.coeff}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </TitleCard>
    )
}

export default StaticLogist