import React, { useEffect } from 'react'
import SearchList from "../../../components/list-otchet/searchList";
import { useSelector, useDispatch } from 'react-redux';
import { fetchMonacoDatas } from '../../../app/slices/data-otchets/monacodatasSlice';
import { Navigate } from 'react-router-dom'

export default function SearchMonacoList() {

    const datasItog = useSelector((state) => state.monacoDatas);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMonacoDatas());
    }, [dispatch]);

    const requiredRoles = [
        "admin",
        "admin.monaco",
        "zam.monaco",
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles) || roles.roles.role.includes('ВМ МНК')) {
        return (
            <SearchList datas={datasItog.data} />
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}