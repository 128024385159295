import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Header from '../../containers/Header'
import { fetchSimCardLogist } from '../../app/slices/logist/simCardLogistSlice'
import SimcardLogist from './components/simcardLogist'

export default function SimCardFboxLog() {

    const dispatch = useDispatch();
    const simcard = useSelector((state) => state.simCardLogs);
    useEffect(() => {
        dispatch(fetchSimCardLogist())
    }, [dispatch]);
    return (
        <div>
            <Header />
            {
                typeof simcard.dataSim.fbox === 'object' ?
                    (
                        <div className='p-10'>
                            <SimcardLogist
                                datas={simcard.dataSim.fbox}
                                api='NewotdelLogs'
                                addSlot='simcardnewotdellog'
                                slot='slotnewotdellog'
                                update='simCardNewotdelLogs'
                                title='Отдел FBOX.KG'
                            />
                        </div>
                    ) : <div>Загрузка...</div>
            }
        </div>
    )
}