import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const buyerSlice = createSlice({
    name: 'buyer-raiting',
    initialState: {
        data: [],
    },
    reducers: {
        fetchDataSuccess: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { fetchDataSuccess } = buyerSlice.actions;

export const fetchraitingmanager = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/raitingbuyer');
            dispatch(fetchDataSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export default buyerSlice.reducer;
