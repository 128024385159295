import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPageTitle } from '../../features/common/headerSlice'
import { NavLink } from 'react-router-dom'
import BarChartFor from '../../components/raitings/forManagerLogist'
import { fetchSimCard } from '../../app/slices/managers/simCardSlice'
import DetailManager from '../../components/raitings/detailManager'
import { fetchfenixLog } from '../../app/slices/logist/fenixlogSlice'
import { Navigate } from 'react-router-dom'
import { fetchfenixManager } from '../../app/slices/managers/fenixmanagerSlice'
import DetailLogistbeta from '../../components/raitings/detaillog'
import UserGroupIcon from '@heroicons/react/24/outline/UserGroupIcon'
import UsersIcon from '@heroicons/react/24/outline/UsersIcon'
import CircleStackIcon from '@heroicons/react/24/outline/CircleStackIcon'
import CreditCardIcon from '@heroicons/react/24/outline/CreditCardIcon'
import DashboardStats from '../../features/dashboard/components/DashboardStats'
import { fetchitogs } from '../../app/slices/itogs/itogsSlice'
import { fetchLogistAdmin } from '../../app/slices/logist/logistandadmin'
import AdminLogist from '../components/tables/adminLogist'

function FenixPage() {

    const dispatch = useDispatch()

    const simCard = useSelector(state => state.simCards)
    const simCardFenixLogs = useSelector((state) => state.fenixlogist)
    const managers = useSelector((state) => state.fenixmanager.datasItog)
    const itogs = useSelector((state) => state.itogs.datasItog)
    const logistAdmin = useSelector(state => state.logistadmin.datasItog)
    const filteredData = logistAdmin.admin?.filter(el => el.team === 'ilyas')
    const filteredDataLogist = logistAdmin.logist?.filter(el => el.team === 'ilyas')

    useEffect(() => {
        dispatch(setPageTitle({ title: "Отдел Ильяс" }))
        dispatch(fetchfenixLog())
        dispatch(fetchfenixManager())
        dispatch(fetchitogs())
        dispatch(fetchSimCard())
        dispatch(fetchLogistAdmin())
    }, [dispatch]);


    const buyers = managers.map(elem => {
        return elem.buyerLength
    }).reduce((acc, num) => acc + num, 0)

    const logist = simCardFenixLogs.datasItog.map(elem => {
        return elem.logistLength
    }).reduce((acc, num) => acc + num, 0)

    const curators = managers.map(elem => {
        return elem.curator
    }).map(sentence => sentence.split(" ")[0]);

    const admins = simCardFenixLogs.datasItog.map(elem => {
        return elem.curator
    }).map(sentence => sentence.split(" ")[0]);

    const datas = managers.map(elem => {
        return elem.percentItog
    })

    const datasLogist = simCardFenixLogs.datasItog.map(elem => {
        return elem.percentItog
    })

    const statsData = [
        { title: "Кол-во байеров", value: buyers, icon: <UserGroupIcon className='w-8 h-8' />, description: "" },
        { title: "Кол-во САДМ", value: logist, icon: <UsersIcon className='w-8 h-8' />, description: "" },
        { title: "Общий итог комиссии", value: typeof itogs.totalAllItog === 'object' ? itogs.totalAllItog.fenix.itog : 0, icon: <CreditCardIcon className='w-8 h-8' />, description: "за текущий месяц" },
        { title: "Общий итог индексов", value: typeof itogs.totalAllItog === 'object' ? itogs.totalAllItog.fenix.index : '', icon: <CircleStackIcon className='w-8 h-8' />, description: "за текущий месяц" },
    ]

    const requiredRoles = [
        "admin",
        "ilyas.admin",
        "ilyas.zam",
        "ilyas.topmanager",
        "it.admin",
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles) || roles.roles.role.includes("ВМ ОЛМ")) {
        return (
            typeof simCard.dataSim.fenix === 'object'
                && typeof simCardFenixLogs.datasItog == 'object'
                && typeof managers === 'object'
                ?
                (<div>
                    <div className='grid lg:grid-cols-3'>
                        <NavLink to='/add-fenix-otchet' className="stats shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Создать отчет</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/list-fenix-otchet' className="stats shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Ежедневные отчеты</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/list-fenix-manager-otchet' className="stats shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Отчеты для менеджеров</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/clients-fenix' className="stats shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Привлеченные клиенты</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/schet-fenix' className="stats shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Счет фактуры</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="grid lg:grid-cols-4 mt-2 md:grid-cols-2 grid-cols-1 gap-6">
                        {
                            statsData.map((d, k) => {
                                return (
                                    <DashboardStats key={k} {...d} colorIndex={k} />
                                )
                            })
                        }
                    </div>

                    <div className='flex flex-col lg:flex-row gap-5 mt-10 mb-10'>
                        <BarChartFor labels={curators} datas={datas} title={'Рейтинг СМ'} />
                        <BarChartFor labels={admins} datas={datasLogist} title={'Рейтинг САДМ'} />
                    </div>
                    <div className='flex flex-col gap-10'>
                        <DetailManager data={managers} />
                        <DetailLogistbeta data={simCardFenixLogs.datasItog} />
                    </div>
                    <div className='flex flex-col gap-10 mt-10'>
                        <AdminLogist select={'админы'} data={filteredData} />
                        <AdminLogist select={'логисты'} data={filteredDataLogist} />
                    </div>
                </div>) : <div>Загрузка...</div>

        )
    } else {
        <Navigate to="/access" replace />;
    }
}

export default FenixPage