import React from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default function TableSchet({ datas }) {

    const generatePDF = () => {
        const input = document.getElementById('pdfContent');

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 210;
                const imgHeight = canvas.height * imgWidth / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save("download.pdf");
            });
    }

    return (
        <div>
            {
                typeof datas === 'object' ? (
                    datas.length ? datas.map(elem => {
                        return (
                            <div key={elem._id} id='pdfContent' className='w-full h-full py-20 mx-auto px-20'>
                                <button onClick={generatePDF}>Скачать PDF</button>
                                <h1 className='text-[#11bac9] font-bold text-2xl mb-10'>СЧЁТ-ФАКТУРА</h1>
                                <div className='flex justify-between'>
                                    <div>
                                        <a href="" className='text-[#11bac9] flex items-center h-[30px] text-sm'>Instagram: dordoi.fashion & dordoi_online_shopping</a>
                                        <p className='text-[#157889] h-[30px] flex items-center text-sm'>Р-к Дордой, Мир Трико,Кербен,43-44</p>
                                    </div>
                                    <div className='flex justify-center items-center flex-col'>
                                        <h1 className='w-[150px] flex justify-center items-center text-white h-[30px] bg-[#11bac9]'>дата заказа</h1>
                                        <p className='w-[150px] flex justify-center items-center h-[30px] border'>{elem.datas}</p>
                                    </div>
                                </div>
                                <table className='w-full text-sm mt-5'>
                                    <thead className='bg-[#11bac9] font-semibold text-white h-[30px]'>
                                        <tr>
                                            <td className='border' colSpan='2'>информация</td>
                                            <td className='border' colSpan='2'>получатель</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='border p-1'>менеджер:</td>
                                            <td className='border p-1'>{elem.manager}</td>
                                            <td className='border p-1'>фио:</td>
                                            <td className='border p-1'>{elem.FIO}</td>
                                        </tr>
                                        <tr>
                                            <td className='border p-1'>админ:</td>
                                            <td className='border p-1'>{elem.admin}</td>
                                            <td className='border p-1'>город:</td>
                                            <td className='border p-1'>{elem.city}</td>
                                        </tr>
                                        <tr>
                                            <td className='border p-1'>статус:</td>
                                            <td className='border p-1'>{elem.status}</td>
                                            <td className='border p-1'>банк:</td>
                                            <td className='border p-1'>{elem.bank}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-full text-sm mt-5'>
                                    <thead className='bg-[#11bac9] font-semibold text-white h-[30px]'>
                                        <tr>
                                            <td className='border text-center'>перевод</td>
                                            <td className='border text-center'>валюта</td>
                                            <td className='border text-center'>курс</td>
                                            <td className='border text-center'>сом</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            elem.transfer.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='border text-center'>{item.perevod}</td>
                                                        <td className='border text-center'>{item.valuta}</td>
                                                        <td className='border text-center'>{item.curs}</td>
                                                        <td className='border text-center'>{item.summa}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <table className='w-full text-sm mt-5'>
                                    <thead className='bg-[#11bac9] font-semibold text-white h-[30px]'>
                                        <tr>
                                            <td className='border text-center'>остаток</td>
                                            <td className='border text-center'>бюджет</td>
                                            <td className='border text-center'>баланс (сом)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='border text-center'>{elem.ostatok}</td>
                                            <td className='border text-center'>{elem.budjet}</td>
                                            <td className='border text-center'>{elem.balans}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-full text-sm mt-10'>
                                    <thead className='bg-[#11bac9] font-semibold text-white h-[30px]'>
                                        <tr>
                                            <td className='border'>наименование позиции</td>
                                            <td className='border'>кол-во</td>
                                            <td className='border'>цена (сом)</td>
                                            <td className='border'>сумма (сом)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            elem.position.map(item => (
                                                <tr key={item._id}>
                                                    <td className='border p-2'>{item.name}</td>
                                                    <td className='border p-2'>{item.count}</td>
                                                    <td className='border p-2'>{item.price}</td>
                                                    <td className='border p-2'>{item.summa}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='flex justify-between items-center mt-10'>
                                    <div className='w-[50%] text-center'>
                                        Жалобы и предложения по номеру: <br />
                                        996 500 996 500 Кайрат (Руководитель) <br />
                                        Заказ должен производится в инд.группах
                                        <h1 className='font-bold text-xl p-2 text-[#157889]'>Благодарим за заказ🫶🏻</h1>
                                    </div>
                                    <table className='w-[50%] bg-[#edfefe] text-sm'>
                                        <tr>
                                            <td className='border p-1'>Общая сумма товара</td>
                                            <td className='border p-1'>{elem.all_sum}</td>
                                        </tr>
                                        <tr>
                                            <td className='border p-1'>Упаковка / тачка / отправка</td>
                                            <td className='border p-1'>{elem.upakovka}</td>
                                        </tr>
                                        <tr>
                                            <td className='border p-1'>Доставка / погрузка / пресс</td>
                                            <td className='border p-1'>{elem.dostavka}</td>
                                        </tr>
                                        <tr>
                                            <td className='border p-1'>Комиссия 6%</td>
                                            <td className='border p-1'>{elem.comission}</td>
                                        </tr>
                                        <tr>
                                            <td className='border p-1'>итого</td>
                                            <td className='border p-1'>{elem.itogs}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className='text-center mt-10 text-sm'>
                                    Настоятельно рекомендуем периодически созваниваться с перевозчиком <br />
                                    Просьба сообщить при получении груза <br />
                                    dordoi.fashion <br />
                                </div>
                            </div>
                        )
                    }) : 'пока нет данных...'
                ) : 'загрузка...'
            }
        </div>
    )
}