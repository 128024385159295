import React from 'react'
import Simcard from './components/simcard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Header from '../../containers/Header'
import { fetchSimCard } from '../../app/slices/managers/simCardSlice'

export default function SimCardManager() {

    const dispatch = useDispatch();
    const simcard = useSelector((state) => state.simCards);
    useEffect(() => {
        dispatch(fetchSimCard())
    }, [dispatch]);
    return (
        <div>
            <Header />
            {
                typeof simcard.dataSim.lider === 'object' ?
                    (
                        <div className='p-10'>
                            <Simcard
                                datas={simcard.dataSim.managers}
                                api='managers'
                                addSlot='simcardManagers'
                                slot='slotsManagers'
                                update='simcardmanagers'
                                title='Старшие менеджеры'
                                name='Менеджер'
                            />
                        </div>
                    ) : <div>Загрузка...</div>
            }
        </div>
    )
}