import TitleCard from "../../../components/Cards/TitleCard"

function StaticLogistLastMoonth() {

    return (
        <TitleCard title={"Рейтинг старших админ среди отделов"}>
            <h1 className="mt-5 mb-5">за последние 3 месяца</h1>
            <h1 className="lg:text-[11px] text-[9px] mb-2">формула: коефф = (оборот / кол-во логистов)</h1>
            <div className="overflow-x-auto">
                <table className="w-full lg:text-[12px] text-[9px]">
                    <thead>
                        <tr className="bg-[#ccc]/30 dark:bg-black/20">
                            <th className="border p-2">топ</th>
                            <th className="border p-2">имя</th>
                            <th className="border p-2">кол-во админов</th>
                            <th className="border p-2">коефф</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border p-2"></td>
                            <td className="border lg:p-3 p-2 w-full"></td>
                            <td className="border p-2 text-center"></td>
                            <td className="border p-2 text-center"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </TitleCard>
    )
}

export default StaticLogistLastMoonth