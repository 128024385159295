import React, { useState } from 'react'
import TableItog from './components/tableItog'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchitogs } from '../../app/slices/itogs/itogsSlice'
import TitleCard from '../../components/Cards/TitleCard';
import PieChart from '../../features/charts/components/PieChart';
import { NavLink } from 'react-router-dom';
import { setPageTitle } from '../../features/common/headerSlice';
import { Navigate } from 'react-router-dom'
import LoadAnimate from '../components/UI/loadanimate';

export default function ItogResult() {

    const itogs = useSelector(({ itogs }) => itogs.datasItog)
    const totalAllItog = itogs.totalAllItog
    const otdel = itogs.otdel
    const dispatch = useDispatch();
    const labels = ['Лидер', 'Монако', 'Ильяс', 'Туран', 'Liberty']
    const data = []
    if (typeof itogs.totalAllItog === 'object') {
        data.push(totalAllItog.lider.itog)
        data.push(totalAllItog.monaco.itog)
        data.push(totalAllItog.fenix.itog)
        data.push(totalAllItog.turan.itog)
        data.push(totalAllItog.liberty.itog)
    }
    const [selectedOtdel, setSelectedOtdel] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalRaznica, setTotalRaznica] = useState(0);

    useEffect(() => {
        dispatch(setPageTitle({ title: "IT отдел" }))
        dispatch(fetchitogs());
    }, [dispatch]);

    const requiredRoles = [
        "admin",
        "it.admin"
    ];

    useEffect(() => {
        if (selectedOtdel) {
            let filteredData = otdel[selectedOtdel];

            const parseDate = (dateString) => {
                const [day, month, year] = dateString.split('.');
                return new Date(`${year}-${month}-${day}`);
            };

            if (startDate) {
                filteredData = filteredData.filter(item => parseDate(item.date) >= parseDate(startDate));
            }

            if (endDate) {
                const endDatePlusOne = new Date(parseDate(endDate));
                endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

                filteredData = filteredData.filter(item => parseDate(item.date) < endDatePlusOne);
            }

            const totalRaznica = filteredData.reduce((acc, item) => acc + item.itog[0].raznica, 0);
            setTotalRaznica(totalRaznica);
        } else {
            setTotalRaznica(0);
        }
    }, [selectedOtdel, startDate, endDate, otdel]);
    const handleOtdelChange = (event) => {
        setSelectedOtdel(event.target.value);
        setTotalRaznica(0);
    };

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles)) {
        return (
            typeof otdel === 'object' && typeof totalAllItog === 'object' && totalAllItog.allItogs.itog >= 0 ? (
                <div>
                    <div className='grid grid-cols-2 mb-5'>
                        <NavLink to='/search-itogs' className="bg-white rounded-xl dark:bg-slate-700 shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Поиск отчетов</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/search-client-otchet' className="bg-white dark:bg-slate-700 rounded-xl shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Поиск клиента</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/clients-all' className="bg-white dark:bg-slate-700 rounded-xl shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300 lg:text-sm text-[10px]">Привлеченные клиенты</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/it-manager-all' className="bg-white dark:bg-slate-700 rounded-xl shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300 lg:text-sm text-[10px]">Ведущие менеджеры</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/it-buyers' className="bg-white dark:bg-slate-700 rounded-xl shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Байеры</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/it-smanager' className="bg-white dark:bg-slate-700 rounded-xl shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Менеджеры</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink to='/incoming-and-outgoing' className="bg-white rounded-xl dark:bg-slate-700 shadow cursor-pointer mb-2 mr-2">
                            <div className="stat">
                                <div className="stat-title dark:text-slate-300">Приходы и расходы</div>
                                <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className='flex lg:flex-row flex-col gap-3 mb-10'>
                        <TitleCard title={'за текущий месяц'}>
                            <table className='table w-full'>
                                <thead>
                                    <tr>
                                        <th>общий итог комиссии:<span className='ml-5 text-blue-600'>{totalAllItog.allItogs.itog}</span> </th>
                                    </tr>
                                    <tr>
                                        <th>общий итог индекс:<span className='ml-5 text-blue-600'>{totalAllItog.allItogs.index}</span> </th>
                                    </tr>
                                    <tr>
                                        <th>касса:<span className='ml-5 text-blue-600'>{totalAllItog.allItogs.allItog}</span></th>
                                    </tr>
                                </thead>
                            </table>
                            <table className='w-full table mt-1 text-center'>
                                <thead>
                                    <tr>
                                        <th colSpan='2'>Лидер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border'>комиссия: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.lider.itog}</span></th>
                                        <th className='border'>индекс: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.lider.index}</span></th>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='w-full table mt-1 text-center'>
                                <thead>
                                    <tr>
                                        <th colSpan='2'>Монако</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border'>комиссия: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.monaco.itog}</span></th>
                                        <th className='border'>индекс: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.monaco.index}</span></th>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='w-full table mt-1 text-center'>
                                <thead>
                                    <tr>
                                        <th colSpan='2'>Ильяс</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border'>комиссия: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.fenix.itog}</span></th>
                                        <th className='border'>индекс: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.fenix.index}</span></th>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='w-full table mt-1 text-center'>
                                <thead>
                                    <tr>
                                        <th colSpan='2'>Туран</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border'>комиссия: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.turan.itog}</span></th>
                                        <th className='border'>индекс: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.turan.index}</span></th>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='w-full table mt-1 text-center'>
                                <thead>
                                    <tr>
                                        <th colSpan='2'>Liberty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='border'>комиссия: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.liberty.itog}</span></th>
                                        <th className='border'>индекс: <span className='text-blue-600 ml-2 text-[12px]'>{totalAllItog.liberty.index}</span></th>
                                    </tr>
                                </tbody>
                            </table>
                        </TitleCard>
                        <PieChart
                            labels={labels}
                            title={'Комиссия'}
                            subLabel={'комиссия за текущий месяц'}
                            datas={data}
                        />
                    </div>
                    <TitleCard title={'Руководительские % за текущий месяц'}>
                        <div className="p-4 bg-white dark:bg-slate-800 rounded-lg shadow-md">
                            <p className="text-lg font-semibold mb-2">Выбрать отдел</p>
                            <select
                                className="w-full p-2 mb-4 bg-gray-100 dark:bg-slate-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={selectedOtdel}
                                onChange={handleOtdelChange}
                            >
                                <option value=''>Не выбрано</option>
                                <option value='lider'>Лидер</option>
                                <option value='monaco'>Монако</option>
                                <option value='fenix'>Ильяс</option>
                                <option value='turan'>Туран</option>
                                <option value='liberty'>Liberty</option>
                            </select>
                            <div className="mt-3">
                                <p className="text-lg font-semibold mb-2">Выбрать период</p>
                                <div className="mb-4">
                                    <p className="text-sm font-medium mb-1">От</p>
                                    <input
                                        type="date"
                                        className="w-full p-2 bg-gray-100 dark:bg-slate-700 rounded-md border border-gray-300 dark:border-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <p className="text-sm font-medium mb-1">До</p>
                                    <input
                                        type="date"
                                        className="w-full p-2 bg-gray-100 dark:bg-slate-700 rounded-md border border-gray-300 dark:border-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            {selectedOtdel && (
                                <div className="mt-3 p-4 bg-gray-100 dark:bg-slate-700 rounded-md">
                                    <p className="text-lg font-semibold">
                                        Результат за {startDate && endDate ? 'выбранный период' : 'текущий месяц'}: {totalRaznica}
                                    </p>
                                </div>
                            )}
                        </div>
                    </TitleCard>
                    <div className='lg:grid lg:grid-cols-2 mt-5 gap-5'>
                        <TitleCard title={'лидер'}>
                            <TableItog datas={otdel.lider} total={totalAllItog.lider} />
                        </TitleCard>
                        <TitleCard title={'монако'}>
                            <TableItog datas={otdel.monaco} total={totalAllItog.monaco} />
                        </TitleCard>
                        <TitleCard title={'ильяс'}>
                            <TableItog datas={otdel.fenix} total={totalAllItog.fenix} />
                        </TitleCard>
                        <TitleCard title={'туран'}>
                            <TableItog datas={otdel.turan} total={totalAllItog.turan} />
                        </TitleCard>
                        <TitleCard title={'liberty'}>
                            <TableItog datas={otdel.liberty} total={totalAllItog.liberty} />
                        </TitleCard>
                    </div>
                </div>
            ) : (
                <div className='w-full gap-2 flex-col flex justify-center items-center'><LoadAnimate />загружаем данных, просим подождать</div>
            )
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}