import React, { useEffect } from 'react'
import TitleCard from '../../../components/Cards/TitleCard'
import { fetchschetlibertyManager } from '../../../app/slices/schetfactura/liberty'
import { useDispatch, useSelector } from 'react-redux'
import TableSchet from '../../components/schet-factura/table-schet'

export default function SchetLiberty() {

    const dispatch = useDispatch()
    const datas = useSelector(state => state.schetfacturaliberty.datasItog)

    useEffect(() => {
        dispatch(fetchschetlibertyManager())
    }, [dispatch])

    return (
        <div>
            <TitleCard>
                <TableSchet datas={datas} />
            </TitleCard>
        </div>
    )
}