import { useDispatch, useSelector } from "react-redux"
import TitleCard from "../../../components/Cards/TitleCard"
import { useEffect } from "react"
import { fetchLiderManager } from '../../../app/slices/managers/lidermanagerSlice'
import { fetchmonacoManager } from '../../../app/slices/managers/monacomanagerSlice'
import { fetchturanManager } from '../../../app/slices/managers/turanmanagerSlice'
import { fetchfenixManager } from '../../../app/slices/managers/fenixmanagerSlice'
import { fetchfboxManager } from '../../../app/slices/managers/fboxmanagerSlice'
import { fetchlibertyManager } from '../../../app/slices/managers/libertymanagerSlice'

function StaticManagers() {

    const dispatch = useDispatch()
    const lider = useSelector(state => state.lidermanager.datasItog)
    const monaco = useSelector(state => state.monacomanager.datasItog)
    const turan = useSelector(state => state.turanmanager.datasItog)
    const fenix = useSelector(state => state.fenixmanager.datasItog)
    const fbox = useSelector(state => state.fboxmanager.datasItog)
    const liberty = useSelector(state => state.libertymanager.datasItog)

    useEffect(() => {
        dispatch(fetchLiderManager())
        dispatch(fetchmonacoManager())
        dispatch(fetchturanManager())
        dispatch(fetchfenixManager())
        dispatch(fetchfboxManager())
        dispatch(fetchlibertyManager())
    }, [dispatch])

    const managers = [...lider, ...monaco, ...turan, ...fenix, ...fbox, ...liberty]

    let data = managers.sort((a, b) => {
        const coeffA = parseFloat(a.allCoeff);
        const coeffB = parseFloat(b.allCoeff);

        if (coeffA < coeffB) {
            return 1;
        } else if (coeffA > coeffB) {
            return -1;
        } else {
            return 0;
        }
    });

    const filteredData = data.filter((item) => item.curator !== '' && !item.curator.includes('ВМ') && !item.curator.includes('FB') && !item.curator.includes("Уулболсун Мамытбекова"));

    return (
        <TitleCard title={"Рейтинг старших среди отделов"}>
            <h1 className="mt-5 mb-5">за текущий месяц</h1>
            <h1 className="lg:text-[11px] text-[9px] mb-2">формула: коефф = (касса / кол-во байер) + (кол-во заказов / кол-во байер)</h1>
            <div className="overflow-x-auto">
                <table className="w-full lg:text-[12px] text-[9px]">
                    <thead>
                        <tr className="bg-[#ccc]/30 dark:bg-black/20">
                            <th className="border p-2">топ</th>
                            <th className="border p-2">имя</th>
                            <th className="border p-2">кол-во байеров</th>
                            <th className="border p-2">коефф</th>
                            <th className="border p-2">д/р</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredData.map((item, index) =>
                                <tr>
                                    <td className='border p-2'>{index + 1}</td>
                                    <td className="border lg:p-3 p-2 w-full">{item.curator}</td>
                                    <td className="border p-2 text-center">{item.buyerLength}</td>
                                    <td className="border p-2 text-center">{item.allCoeff}</td>
                                    <td className="border p-2 text-center text-[6px]">{item.data_register}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </TitleCard>
    )
}

export default StaticManagers