import React, { useEffect, useState } from 'react'
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import Login from './pages/Login'
import Layout from './containers/Layout'
import SimCardLider from './pages/simcard/simcardLider';
import SimCardMonaco from './pages/simcard/simcardMonaco';
import SimCardTuran from './pages/simcard/simcardTuran';
import SimCardFenix from './pages/simcard/simcardFenix';
import SimCardLiderLog from './pages/simcard/logistlider';
import SimCardMonacoLog from './pages/simcard/logistmonaco';
import SimCardFenixLog from './pages/simcard/logistfenix';
import SimCardTuranLog from './pages/simcard/logistturan';
import EditTelegram from './pages/editor/editTG';
import StoriesSection from './features/dashboard/stories/components/StoriesComponent.js';
import SimCardIt from './pages/simcard/simcardit.js';
import SimCardFboxLog from './pages/simcard/logistfbox.js';
import SimCardFbox from './pages/simcard/simcardFbox.js';
import SimCardManager from './pages/simcard/simcardManager.js';
import SimCardLiberty from './pages/simcard/simcardLiberty.js';
import SimCardLibertyLog from './pages/simcard/logistliberty.js';
import LogistAndAdmin from './pages/simcard/logistandadmin.js';


function App() {

  useEffect(() => {
    themeChange(false)
  }, [])

  const PrivateRoute = ({ element }) => {
    const token = JSON.parse(localStorage.getItem("roles"));

    return token ? element : <Navigate to="/login" replace />;
  };

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/story/:categoryName" element={<StoriesSection />} />
        <Route path="/*" element={<PrivateRoute element={<Layout />} />} />
        <Route path="/edit-tgslot" element={<PrivateRoute element={<EditTelegram />} />} />
        <Route path="/simcard-lider" element={<PrivateRoute element={<SimCardLider />} />} />
        <Route path="/simcard-managers" element={<PrivateRoute element={<SimCardManager />} />} />
        <Route path="/simcard-logistandadmin" element={<PrivateRoute element={<LogistAndAdmin />} />} />
        <Route path="/simcard-it" element={<PrivateRoute element={<SimCardIt />} />} />
        <Route path="/simcard-monaco" element={<PrivateRoute element={<SimCardMonaco />} />} />
        <Route path="/simcard-turan" element={<PrivateRoute element={<SimCardTuran />} />} />
        <Route path="/simcard-fenix" element={<PrivateRoute element={<SimCardFenix />} />} />
        <Route path="/simcard-fbox" element={<PrivateRoute element={<SimCardFbox />} />} />
        <Route path="/simcard-liberty" element={<PrivateRoute element={<SimCardLiberty />} />} />
        <Route path="/simcard-logist-lider" element={<PrivateRoute element={<SimCardLiderLog />} />} />
        <Route path="/simcard-logist-monaco" element={<PrivateRoute element={<SimCardMonacoLog />} />} />
        <Route path="/simcard-logist-fenix" element={<PrivateRoute element={<SimCardFenixLog />} />} />
        <Route path="/simcard-logist-turan" element={<PrivateRoute element={<SimCardTuranLog />} />} />
        <Route path="/simcard-logist-fbox" element={<PrivateRoute element={<SimCardFboxLog />} />} />
        <Route path="/simcard-logist-liberty" element={<PrivateRoute element={<SimCardLibertyLog />} />} />
      </Routes>
    </>
  )
}

export default App
