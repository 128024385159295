import React from 'react'
import PersonalManager from './manager/personal-manager'
import PersonalLogist from './logist/personal-logist'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setPageTitle } from '../../features/common/headerSlice'

export default function PersonalPage() {

    const users = JSON.parse(localStorage.getItem('roles'))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle({ title: "личный кабинет" }))
    }, [dispatch])

    return (
        <div>
            {
                users.roles.role.includes('СМ') ?
                    <PersonalManager user={users.roles.role} />
                    : <PersonalLogist user={users.roles.role} />
            }
        </div>
    )
}
