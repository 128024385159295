import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Header from '../../containers/Header'
import SimcardLogistAndAdmin from './components/logistandadmin'
import { fetchLogistAdminim } from '../../app/slices/logist/simcardlogistandadmin'

export default function LogistAndAdmin() {

    const dispatch = useDispatch();
    const simcard = useSelector((state) => state.logistadminsim.datasItog);
    useEffect(() => {
        dispatch(fetchLogistAdminim())
    }, [dispatch]);

    return (
        <div>
            <Header />
            {
                simcard && simcard.length ?
                    (
                        <div className='p-10'>
                            <SimcardLogistAndAdmin
                                datas={simcard}
                            />
                        </div>
                    ) : <div>Загрузка...</div>
            }
        </div>
    )
}