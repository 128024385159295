import React from 'react'
import Simcard from './components/simcard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Header from '../../containers/Header'
import { fetchSimCard } from '../../app/slices/managers/simCardSlice'

export default function SimCardTuran() {

    const dispatch = useDispatch();
    const simcard = useSelector((state) => state.simCards);
    useEffect(() => {
        dispatch(fetchSimCard())
    }, [dispatch]);
    return (
        <div>
            <Header />
            {
                typeof simcard.dataSim.turan === 'object' ?
                    (
                        <div className='p-10'>
                            <Simcard
                                datas={simcard.dataSim.turan}
                                api='Turans'
                                addSlot='simcardTurans'
                                slot='simcardTuranslot'
                                update='simCardTurans'
                                title='Отдел Туран'
                                name='Байер'
                            />
                        </div>
                    ) : <div>Загрузка...</div>
            }
        </div>
    )
}