import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../../Api'


const profitSlice = createSlice({
    name: 'expences-all-data',
    initialState: {
        profit: [],
    },
    reducers: {
        setProfit(state, action) {
            state.profit = action.payload
        }
    },
})

export const { setProfit } = profitSlice.actions
export default profitSlice.reducer

export const fetchProfitDatas = () => async (dispatch) => {
    try {
        const response = await api.get('/profit-all-datas')
        dispatch(setProfit(response.data))
    } catch (error) {
        console.log(error);
    }
}