import React from 'react'
import OtchetList from "../../../components/list-otchet/listOtchet";
import { Navigate } from 'react-router-dom'
import LoadAnimate from '../../components/UI/loadanimate';

export default function OtchetListFenix({ datas }) {

    const requiredRoles = [
        "admin",
        "ilyas.admin",
        "ilyas.zam"
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles) || roles.roles.role.includes("ВМ ОЛМ")) {
        return (
            typeof datas === 'object' ? (
                <OtchetList datas={datas} api="fenix" />
            ) : (
                <div className='w-full gap-2 flex-col flex justify-center items-center'><LoadAnimate />загружаем данных, просим подождать</div>
            )
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}