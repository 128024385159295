import React, { useEffect, useState } from 'react'
import TitleCard from '../../../components/Cards/TitleCard'
import { fetchschetfboxManager } from '../../../app/slices/schetfactura/fbox'
import { useDispatch, useSelector } from 'react-redux'
import TableSchet from '../../components/schet-factura/table-schet'

export default function SchetFbox() {

    const dispatch = useDispatch()
    const datas = useSelector(state => state.schetfacturafbox.datasItog)

    useEffect(() => {
        dispatch(fetchschetfboxManager())
    }, [dispatch])

    return (
        <div>
            <TitleCard>
                <TableSchet datas={datas} />
            </TitleCard>
        </div>
    )
}