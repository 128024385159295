import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const schetfacturaleaderSlice = createSlice({
    name: 'schet-factura-leader',
    initialState: {
        datasItog: [],
    },
    reducers: {
        fetchDataSuccess: (state, action) => {
            state.datasItog = action.payload;
        },
    },
});

export const { fetchDataSuccess } = schetfacturaleaderSlice.actions;

export const fetchschetleaderManager = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/schetfactura-leader');
            dispatch(fetchDataSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export default schetfacturaleaderSlice.reducer;