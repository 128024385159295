import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const logistadminsimSlice = createSlice({
    name: 'logistadminsim',
    initialState: {
        datasItog: [],
    },
    reducers: {
        fetchDataRequest: (state, action) => {
            state.datasItog = action.payload;
        },
    },
});

export const { fetchDataRequest } = logistadminsimSlice.actions;

export const fetchLogistAdminim = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/adminlogist');
            dispatch(fetchDataRequest(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export default logistadminsimSlice.reducer;
