import React, { useEffect } from 'react'
import ListManager from '../../../components/list-otchet/list-manager'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLibertyDatas } from '../../../app/slices/data-otchets/libertyDatasSlice';

export default function ListManagerLiberty() {

    const datasItogLiberty = useSelector((state) => state.libertyDatas.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLibertyDatas())
    }, [dispatch]);

    const requiredRoles = [
        "admin",
        "liberty.admin",
        "Азиза Жолболдиева ВМ ТЛБ"
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if ((roles && hasAccess(roles.roles.role, requiredRoles)) || roles.roles.role.includes('СМ ТЛБ')) {
        return (
            <div>
                <ListManager datas={datasItogLiberty} api='liberty' />
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}