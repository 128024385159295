import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const logistadminSlice = createSlice({
    name: 'logistadmin',
    initialState: {
        datasItog: [],
        datasItoglast: [],
    },
    reducers: {
        fetchDataRequest: (state, action) => {
            state.datasItog = action.payload;
        },
        fetchDataRequestlast: (state, action) => {
            state.datasItoglast = action.payload;
        },
    },
});

export const { fetchDataRequest, fetchDataRequestlast } = logistadminSlice.actions;

export const fetchLogistAdmin = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/adminlogistculc');
            dispatch(fetchDataRequest(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchLogistAdminlast = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/adminculc45days');
            dispatch(fetchDataRequestlast(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};

export default logistadminSlice.reducer;
