import React, { useEffect } from 'react'
import TitleCard from '../../components/Cards/TitleCard'
import TableItog from '../it/components/tableItog'
import { useDispatch, useSelector } from 'react-redux'
import { fetchitogs } from '../../app/slices/itogs/itogsSlice'
import TableItogOnline from './components/table-otchet'

export default function OnlineOtchets() {

    const itogs = useSelector(({ itogs }) => itogs.datasItog.otdel)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchitogs());
    }, [dispatch]);

    return (
        <div>
            {
                typeof itogs === 'object' ? (
                    <div className='lg:grid lg:grid-cols-2 mt-5 gap-5'>
                        <TitleCard title={'лидер'}>
                            <TableItogOnline datas={itogs.lider} />
                        </TitleCard>
                        <TitleCard title={'монако'}>
                            <TableItogOnline datas={itogs.monaco} />
                        </TitleCard>
                        <TitleCard title={'ильяс'}>
                            <TableItogOnline datas={itogs.fenix} />
                        </TitleCard>
                        <TitleCard title={'туран'}>
                            <TableItogOnline datas={itogs.turan} />
                        </TitleCard>
                        <TitleCard title={'fbox.kg'}>
                            <TableItogOnline datas={itogs.fbox} />
                        </TitleCard>
                        <TitleCard title={'liberty'}>
                            <TableItogOnline datas={itogs.liberty} />
                        </TitleCard>
                    </div>
                ) : 'Загрузка...'
            }
        </div>
    )
}
