import React, { useEffect } from 'react'
import ListManager from '../../../components/list-otchet/list-manager'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFboxDatas } from '../../../app/slices/data-otchets/fboxDatasSlice';

export default function ListManagerFbox() {

    const datasItogFbox = useSelector((state) => state.fboxDatas.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFboxDatas())
    }, [dispatch]);

    const requiredRoles = [
        "admin",
        "fbox.admin",
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if ((roles && hasAccess(roles.roles.role, requiredRoles)) || roles.roles.role.includes('СМ FB') || roles.roles.role.includes("ВМ FB")) {
        return (
            <div>
                <ListManager datas={datasItogFbox} api='fbox' />
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}