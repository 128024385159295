import photos1 from '../img/photos.png'
import photos2 from '../img/photos1.png'

const storiesData = {
  новости: [
    {
      image: `${process.env.REACT_APP_CLOUDINARY_URL}/food1_jvs30q.jpg`,
      title: "created by fashion R",
      text: `Теперь новости будут появляться здесь
      
Старшие менеджеры, ведущие менеджеры, руководители и их заместители могут отслеживать деятельность своих байеров и логистов: количество заказов, оборот, статус активности итд."
      `,
      captionColor: "white",
      bgColor: "#3498db",
      type: "titleImageTextPost",
    },
    {
      image: photos1,
      caption: "привет",
      captionColor: "white",
      type: "imageCaptionPost",
    },
    {
      image: photos2,
      caption: "салам",
      captionColor: "white",
      type: "imageCaptionPost",
    },
  ],
  тех_поддержка: [
    {
      title: "x% байер , x%логист",
      text: `Правила использования привлеченного клиента в отчете:
Когда вы первый раз вводите новое имя привлеченного клиента в отчете, например, "Анна Москва", просим в последующем также писать аналогично.
      
Допускаются следующие варианты:
      - "Анна москва"
      - "АННА МОСКВА"
      - "анна москва"
      - "аннаМосква"

Не допускаются:
      -"Москва Анна"
      - "анна"
      - "Anna moskva"
      - "аннна Москвааа"
      - "Анна Моск" итд`,
      image: `${process.env.REACT_APP_CLOUDINARY_URL}/tech1_tzyeq4.jpg`,
      bgColor: "#F58529",
      type: "titleImageTextPost",
    },
    {
      title: "Условия",
      text: `Клиент перестанет считаться привлеченным, если выполнено одно из следующих условий:

      - количество заказов достигло 10;
      - сумма кэшбека (ваш %) достигла 50 000;
      - срок заказа составил 2 месяца.
      Условия будут пересматриваться и корректироваться каждые 60 дней
      
Будут меняться %, сроки, кол-во заказов и общая сумма, в большую или меньшую сторону исходя из возможностей отделов и техподдержки.`,
      image: `${process.env.REACT_APP_CLOUDINARY_URL}/tech2_dfriyn.jpg`,
      bgColor: "#2196F3",
      type: "titleImageTextPost",
    },
  ],
  актуальное: [
    {
      image: `${process.env.REACT_APP_CLOUDINARY_URL}/travel1_erwvl6.jpg`,
      caption: "Beautiful Landscape",
      captionColor: "white",
      type: "imageCaptionPost",
    },
    {
      image: `${process.env.REACT_APP_CLOUDINARY_URL}/travel2_o9gywv.jpg`,
      caption: "Hiking",
      captionColor: "white",
      type: "imageCaptionPost",
    },
  ],
};

export default storiesData;
