import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import TitleCard from '../../components/Cards/TitleCard';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChartFor({ title, labels, datas }) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Комиссия %',
                data: datas,
                backgroundColor: 'rgb(255, 51, 133)',
            },
        ],
    };

    return (
        <TitleCard title={title} topMargin="mt-2">
            <Bar options={options} data={data} />
        </TitleCard>

    )
}


export default BarChartFor