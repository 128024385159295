import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const itogSlice = createSlice({
    name: 'app',
    initialState: {
        loading: false,
        otdel: {
            lider: [],
            monaco: [],
            fenix: [],
            turan: [],
            fbox: [],
        },
        totalAllItog: {
            lider: {
                itog: 0,
                index: 0,
                allItog: 0,
                percentItog: 0,
                percentIndex: 0,
            },
            monaco: {
                itog: 0,
                index: 0,
                allItog: 0,
                percentItog: 0,
                percentIndex: 0,
            },
            fenix: {
                itog: 0,
                index: 0,
                allItog: 0,
                percentItog: 0,
                percentIndex: 0,
            },
            turan: {
                itog: 0,
                index: 0,
                allItog: 0,
                percentItog: 0,
                percentIndex: 0,
            },
            fbox: {
                itog: 0,
                index: 0,
                allItog: 0,
                percentItog: 0,
                percentIndex: 0,
            },
            allItogs: {
                itog: 0,
                itogIndex: 0,
                allItog: 0,
            },
        },
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setOtdel: (state, action) => {
            state.otdel = action.payload;
        },
        setTotalAllItog: (state, action) => {
            state.totalAllItog = action.payload;
        },
    },
});

export const { setLoading, setOtdel, setTotalAllItog } = itogSlice.actions;


const fetchDataFromAPI = async (endpoint) => {
    try {
        const response = await axios.get(`https://fashion-backend-r8hh.onrender.com/test/${endpoint}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchDataItogs = () => async (dispatch) => {
    try {
        dispatch(setLoading(true))

        const [liderData, monacoData, fenixData, turanData, fboxData] = await Promise.all([
            fetchDataFromAPI('liderdatas'),
            fetchDataFromAPI('monacodatas'),
            fetchDataFromAPI('fenixdatas'),
            fetchDataFromAPI('turandatas'),
            fetchDataFromAPI('newotdeldatas'),
        ]);

        const currentMonth = new Date().toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' });

        const filterDataByCurrentMonth = (data) => {
            return data.filter((item) => {
                const itemDate = item.date;
                const itemMonthYear = itemDate.split('.').slice(1).join('/');
                return itemMonthYear === currentMonth;
            });
        };

        const filteredLiderData = filterDataByCurrentMonth(liderData);
        const filteredMonacoData = filterDataByCurrentMonth(monacoData);
        const filteredFenixData = filterDataByCurrentMonth(fenixData);
        const filteredTuranData = filterDataByCurrentMonth(turanData);
        const filteredFboxData = filterDataByCurrentMonth(fboxData);

        dispatch(setOtdel({ lider: filteredLiderData, monaco: filteredMonacoData, fenix: filteredFenixData, turan: filteredTuranData, fbox: filteredFboxData }));

        const calculateTotalAllItog = (data) => {
            return data.reduce((acc, elem) => {
                const itogSum = elem.itog.reduce((subAcc, item) => {
                    return subAcc + item.allItog;
                }, 0);
                const itogIndexSum = elem.itog.reduce((subAcc, item) => {
                    return subAcc + item.allItogIndex;
                }, 0);
                return {
                    itog: acc.itog + itogSum,
                    index: acc.index + itogIndexSum,
                    allItog: acc.allItog + itogSum + itogIndexSum,
                };
            }, { itog: 0, index: 0, allItog: 0 });
        };

        const totalAllItog = {
            lider: calculateTotalAllItog(filteredLiderData),
            monaco: calculateTotalAllItog(filteredMonacoData),
            fenix: calculateTotalAllItog(filteredFenixData),
            turan: calculateTotalAllItog(filteredTuranData),
            fbox: calculateTotalAllItog(filteredFboxData),
            allItogs: calculateTotalAllItog([...filteredLiderData, ...filteredMonacoData, ...filteredFenixData, ...filteredTuranData, ...filteredFboxData]),
        };

        dispatch(setTotalAllItog(totalAllItog));

        dispatch(setLoading(false));
    } catch (error) {
        console.error(error);
        dispatch(setLoading(false));
    }
};

export default itogSlice.reducer;
