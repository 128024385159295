import React from 'react'
import { NavLink, Navigate } from 'react-router-dom'
import { MdOutlineManageSearch } from "react-icons/md";

export default function OnlinePage() {

    const roles = JSON.parse(localStorage.getItem('roles'));

    const requiredRoles = [
        "admin",
        "it.admin",
        "online.admin"
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    if (roles && hasAccess(roles.roles.role, requiredRoles)) {
        return (
            <div>
                <NavLink to='/online-clients' className="stats shadow cursor-pointer mb-2 mr-2">
                    <div className="stat">
                        <div className="stat-title dark:text-slate-300">Привлеченные клиенты</div>
                        <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>
                        </div>
                    </div>
                </NavLink>
                <NavLink to='/online-otchet-search' className="stats shadow cursor-pointer mb-2 mr-2">
                    <div className="stat">
                        <div className="stat-title dark:text-slate-300">Поиск отчетов</div>
                        <div className={`stat-figure dark:text-slate-300}`}><MdOutlineManageSearch size={20} />
                        </div>
                    </div>
                </NavLink>
            </div>
        )
    } else {
        <Navigate to="/access" replace />;
    }
}
