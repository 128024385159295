import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLiderManager } from '../../../app/slices/managers/lidermanagerSlice'
import { fetchmonacoManager } from '../../../app/slices/managers/monacomanagerSlice'
import { fetchfenixManager } from '../../../app/slices/managers/fenixmanagerSlice'
import { fetchturanManager } from '../../../app/slices/managers/turanmanagerSlice'
import { fetchfboxManager } from '../../../app/slices/managers/fboxmanagerSlice'
import { fetchlibertyManager } from '../../../app/slices/managers/libertymanagerSlice'
import CreditCardIcon from '@heroicons/react/24/outline/CreditCardIcon'
import CircleStackIcon from '@heroicons/react/24/outline/CircleStackIcon'
import TitleCard from '../../../components/Cards/TitleCard'
import { NavLink } from 'react-router-dom'

export default function PersonalManager({ user }) {

    const team = user.includes('ЛДР') ? 'lider'
        : user.includes('МНК') ? 'monaco'
            : user.includes('ТРН') ? 'turan'
                : user.includes('ОЛМ') ? 'fenix'
                    : user.includes('ТЛБ') ? 'liberty'
                        : user.includes('FB') ? 'fbox' : ''

    const dispatch = useDispatch()
    const leader = useSelector(state => state.lidermanager.datasItog)
    const monaco = useSelector(state => state.monacomanager.datasItog)
    const turan = useSelector(state => state.turanmanager.datasItog)
    const fenix = useSelector(state => state.fenixmanager.datasItog)
    const liberty = useSelector(state => state.libertymanager.datasItog)
    const fbox = useSelector(state => state.fboxmanager.datasItog)

    const allteams = [...leader, ...monaco, ...turan, ...fenix, ...liberty, ...fbox]

    const selectedData = allteams.find(manager => manager.curator === user)

    useEffect(() => {
        dispatch(fetchLiderManager())
        dispatch(fetchmonacoManager())
        dispatch(fetchturanManager())
        dispatch(fetchfenixManager())
        dispatch(fetchfboxManager())
        dispatch(fetchlibertyManager())
    }, [dispatch])

    const statsData = [
        { title: "Ваша комиссия 7%", value: typeof selectedData === 'object' && selectedData && Number(selectedData.comission), icon: <CreditCardIcon className='w-8 h-8' />, description: "за текущий месяц" },
        { title: "Доступные средства", value: typeof selectedData === 'object' && selectedData && Number(selectedData.for_withdrawal), icon: <CircleStackIcon className='w-8 h-8' />, description: "для вывода" },
        { title: "Ваш оборот в компании", value: typeof selectedData === 'object' && selectedData && selectedData.totalcom, icon: <CreditCardIcon className='w-8 h-8' />, description: "за текущий месяц" },
        { title: "Среди СМ в отделе", value: typeof selectedData === 'object' && selectedData && selectedData.percentItog, icon: <CreditCardIcon className='w-8 h-8' />, description: "за текущий месяц" },
    ]

    const sortedData = typeof selectedData === 'object' ? [...selectedData.detail].sort((a, b) => b.orders - a.orders) : []

    return (
        <div>
            {
                typeof selectedData === 'object' && selectedData ?
                    (
                        <TitleCard className='w-full h-screen'>
                            <div className='f-full flex justify-center flex-col items-center gap-5 pt-5'>
                                <div className='flex flex-col justify-center items-center gap-5'>
                                    <div className='w-[100px] h-[100px] rounded-full shadow-xl flex justify-center items-center'>
                                        <div className='w-[92px] h-[92px] rounded-full shadow-md border flex justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-20 h-20 ">
                                                <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <h1 className='font-semibold text-cyan-700'>{selectedData.curator}</h1>
                                </div>
                                <div className='w-full grid grid-cols-3'>
                                    <div className='flex flex-col justify-center items-center'>
                                        <h1 className='font-bold stat-title text-cyan-700  dark:text-slate-300 text-[30px]'>{selectedData.buyerLength}</h1>
                                        <p className='text-sm text-gray-400'>кол-во байер</p>
                                    </div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <h1 className='font-bold stat-title text-cyan-700  dark:text-slate-300 text-[30px]'>{selectedData.order}</h1>
                                        <p className='text-sm text-gray-400'>кол-во заказов</p>
                                    </div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <h1 className='font-bold stat-title text-cyan-700  dark:text-slate-300 text-[30px]'>{selectedData.allCoeff}</h1>
                                        <p className='text-sm text-gray-400'>коэффициент</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-4 mt-10 md:grid-cols-2 grid-cols-1 gap-6">
                                {
                                    statsData.map((d, k) => {
                                        return (
                                            <div className="stats shadow ">
                                                <div className="stat ">
                                                    <div className={`stat-figure text-cyan-700`}>{d.icon}</div>
                                                    <div className="stat-title text-[12px]">{d.title}</div>
                                                    <div className={`stat-value text-[25px] text-cyan-700`}>{d.value} {typeof d.value === 'string' ? '%' : 'сом'}</div>
                                                    <div className={"stat-desc"}>{d.description}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <NavLink to={`/add-${team}-otchet`} className="stats shadow cursor-pointer mt-10">
                                <div className="stat">
                                    <div className="stat-title dark:text-slate-300">Создать отчет</div>
                                    <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink to={`/list-${team}-manager-otchet`} className="stats shadow cursor-pointer lg:ml-5 mt-5">
                                <div className="stat">
                                    <div className="stat-title dark:text-slate-300">Ежедневные отчеты</div>
                                    <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                    </svg>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink to={`/manager-sredstv-history`} className="stats shadow cursor-pointer lg:ml-5 mt-5">
                                <div className="stat">
                                    <div className="stat-title dark:text-slate-300">История вывода средств</div>
                                    <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z" clipRule="evenodd" />
                                    </svg>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink to={`/clients-${team}`} className="stats shadow cursor-pointer lg:ml-5 mt-5">
                                <div className="stat">
                                    <div className="stat-title dark:text-slate-300">Привлеченные клиенты</div>
                                    <div className={`stat-figure dark:text-slate-300}`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                    </svg>
                                    </div>
                                </div>
                            </NavLink>
                            <div>
                                {
                                    <table className="lg:table w-full mt-10">
                                        <thead>
                                            <tr className="bg-gray-700 dark:bg-cyan-700 dark:text-white text-white lg:text-black text-[10px]">
                                                <th className="py-2 px-4 font-semibold text-left">Имя</th>
                                                <th className="py-2 px-4 font-semibold text-left">Статус</th>
                                                <th className="py-2 px-4 font-semibold text-left">кол-во заказов</th>
                                                <th className="py-2 px-4 font-semibold text-left">комиссия</th>
                                            </tr>
                                        </thead>
                                        <tbody className='lg:text-[12px] text-[9px]'>
                                            {
                                                sortedData.map(item => {
                                                    return (
                                                        <tr className=''>
                                                            <td className='p-2'>{item.name}</td>
                                                            <td className={item.status === "2" ? 'text-green-500' : 'text-red-500'}>{item.status === "2" ? 'активный' : 'неактивный'}</td>
                                                            <td className='text-center p-2'>{item.orders}</td>
                                                            <td>{item.summa}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </TitleCard>
                    ) : 'Загрузка...'
            }
        </div>
    )
}