import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        dataCLient: [],
        loading: false,
        error: null,
    },
    reducers: {
        fetchDataRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchDataSuccess: (state, action) => {
            state.dataCLient = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchDataFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { fetchDataRequest, fetchDataSuccess, fetchDataFailure } = clientsSlice.actions;

export const fetchClients = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchDataRequest());

            const [responseLider, responseMonaco, responseTuran, responseFenix, resposefbox, responseliberty] = await Promise.all([
                api.get('/leaderclient'),
                api.get('/monacoclient'),
                api.get('/turanclient'),
                api.get('/fenixclient'),
                api.get('/newotdelclient'),
                api.get('/libertyclient'),
            ]);

            const combinedData = {
                lider: responseLider.data,
                monaco: responseMonaco.data,
                turan: responseTuran.data,
                fenix: responseFenix.data,
                fbox: resposefbox.data,
                liberty: responseliberty.data
            };

            dispatch(fetchDataSuccess(combinedData));
        } catch (error) {
            dispatch(fetchDataFailure(error.message));
        }
    };
};

export default clientsSlice.reducer;
