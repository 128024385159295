import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, } from 'react-chartjs-2';
import TitleCard from '../../../components/Cards/TitleCard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ datas, datasindex, title, color, colorindex, titleindex, subtitle }) {

  const options = {
    plugins: {
      legend: {
        position: 'top',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = ['Лидер', 'Монако', 'Ильяс', 'Туран', 'Liberty'];

  const data = {
    labels,
    datasets: [
      {
        label: title,
        data: datas,
        backgroundColor: color,
      },
      {
        label: titleindex,
        data: datasindex,
        backgroundColor: colorindex,
      },
    ],
  };

  return (
    <TitleCard title={`${title} и ${titleindex} в процентах ${subtitle}`}>
      <Bar options={options} data={data} />
    </TitleCard>

  )
}


export default BarChart