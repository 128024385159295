import React, { useEffect } from 'react'
import ListManager from '../../../components/list-otchet/list-manager'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchTuranDatas } from '../../../app/slices/data-otchets/turandatasSlice';

export default function ListManagerTuran() {

    const datasItogTuran = useSelector((state) => state.turanDatas.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTuranDatas())
    }, [dispatch]);

    const requiredRoles = [
        "admin",
        "turan.admin",
        "Сафия Эркебаева ВМ ТРН",
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if ((roles && hasAccess(roles.roles.role, requiredRoles)) || roles.roles.role.includes('СМ ТРН' || roles.roles.role.includes('ВМ ТРН'))) {
        return (
            <div>
                <ListManager datas={datasItogTuran} api='turan' />
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}