import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const simCardSlice = createSlice({
    name: 'simDatas',
    initialState: {
        dataSim: [],
        loadingSim: false,
        errorSim: null,
    },
    reducers: {
        fetchDataRequest: (state) => {
            state.loadingItog = true;
            state.errorSim = null;
        },
        fetchDataSuccess: (state, action) => {
            state.dataSim = action.payload;
            state.loadingSim = false;
            state.errorSim = null;
        },
        fetchDataFailure: (state, action) => {
            state.loadingSim = false;
            state.errorSim = action.payload;
        },
    },
});

export const { fetchDataRequest, fetchDataSuccess, fetchDataFailure } = simCardSlice.actions;

export const fetchSimCard = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchDataRequest());

            const [responseLider, responseMonaco, responseTuran, responseFenix, responseManagers, responseIt, responsefbox, responseliberty] = await Promise.all([
                api.get('/test/simCardLiders'),
                api.get('/test/simCardMonacos'),
                api.get('/test/simCardTurans'),
                api.get('/test/simCardFenixes'),
                api.get('/test/simcardmanagers'),
                api.get('/test/simCardglobal'),
                api.get('/test/simCardNewOtdel'),
                api.get('/test/simCardLiberty'),
            ]);

            const combinedData = {
                lider: responseLider.data,
                monaco: responseMonaco.data,
                turan: responseTuran.data,
                fenix: responseFenix.data,
                managers: responseManagers.data,
                it: responseIt.data,
                fbox: responsefbox.data,
                liberty: responseliberty.data,
            };

            dispatch(fetchDataSuccess(combinedData));
        } catch (error) {
            dispatch(fetchDataFailure(error.message));
        }
    };
};

export default simCardSlice.reducer;
