import React, { useEffect, useState } from 'react'
import TitleCard from '../../../components/Cards/TitleCard'
import { fetchschetfenixManager } from '../../../app/slices/schetfactura/fenix'
import { useDispatch, useSelector } from 'react-redux'
import TableSchet from '../../components/schet-factura/table-schet'

export default function SchetFenix() {

    const dispatch = useDispatch()
    const datas = useSelector(state => state.schetfacturafenix.datasItog)

    useEffect(() => {
        dispatch(fetchschetfenixManager())
    }, [dispatch])

    return (
        <div>
            <TitleCard>
                <TableSchet datas={datas} />
            </TitleCard>
        </div>
    )
}