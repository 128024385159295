import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchLiderManager } from '../../../../app/slices/managers/lidermanagerSlice';
import { fetchmonacoManager } from '../../../../app/slices/managers/monacomanagerSlice';
import { fetchturanManager } from '../../../../app/slices/managers/turanmanagerSlice';
import { fetchfenixManager } from '../../../../app/slices/managers/fenixmanagerSlice';
import { fetchlibertyManager } from '../../../../app/slices/managers/libertymanagerSlice';
import TableManager from './components/table-manager';
export default function ItManagerPage() {

  const dispatch = useDispatch();
  const leadermanager = useSelector((state) => state.lidermanager.datasItog)
  const monacomanager = useSelector((state) => state.monacomanager.datasItog)
  const turanmanager = useSelector((state) => state.turanmanager.datasItog)
  const fenixmanager = useSelector((state) => state.fenixmanager.datasItog)
  const libertymanager = useSelector((state) => state.libertymanager.datasItog)

  useEffect(() => {
    dispatch(fetchLiderManager())
    dispatch(fetchmonacoManager())
    dispatch(fetchturanManager())
    dispatch(fetchfenixManager())
    dispatch(fetchlibertyManager())
  }, [dispatch])

  return (
    <div className="flex flex-col gap-5">
      <TableManager data={leadermanager} title={"Лидер"} />
      <TableManager data={libertymanager} title={"Либерти"} />
      <TableManager data={monacomanager} title={"Монако"} />
      <TableManager data={turanmanager} title={"Туран"} />
      <TableManager data={fenixmanager} title={"Ильяс"} />
    </div>
  );
}