import React from 'react'
import OtchetList from "../../../components/list-otchet/listOtchet";
import { Navigate } from 'react-router-dom'
import { roles_leader } from '../../../constants/roles/LEADER_ROLES';
import LoadAnimate from '../../components/UI/loadanimate';


export default function OtchetListLeader({ datas }) {

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, roles_leader) || roles.roles.role.includes("ВМ ЛДР")) {
        return (
            typeof datas === 'object' ? (
                <OtchetList datas={datas} api="leader" />
            ) : (
                <div className='w-full gap-2 flex-col flex justify-center items-center'><LoadAnimate />загружаем данных, просим подождать</div>
            )
        )
    } else {
        return <Navigate to="/access" replace />;
    }



}