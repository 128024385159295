import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Header from '../../containers/Header'
import { fetchSimCardLogist } from '../../app/slices/logist/simCardLogistSlice'
import SimcardLogist from './components/simcardLogist'

export default function SimCardLibertyLog() {

    const dispatch = useDispatch();
    const simcard = useSelector((state) => state.simCardLogs);
    useEffect(() => {
        dispatch(fetchSimCardLogist())
    }, [dispatch]);
    return (
        <div>
            <Header />
            {
                typeof simcard.dataSim.liberty === 'object' ?
                    (
                        <div className='p-10'>
                            <SimcardLogist
                                datas={simcard.dataSim.liberty}
                                api='LibertyLogs'
                                addSlot='simcardlibertylog'
                                slot='slotlibertylog'
                                update='simCardLibertyLogs'
                                title='Отдел Liberty'
                            />
                        </div>
                    ) : <div>Загрузка...</div>
            }
        </div>
    )
}