import React from 'react';
import { NavLink } from 'react-router-dom';

export default function TableItogOnline({ datas }) {
    const sortedDatas = [...datas].sort((a, b) => {
        const dateA = Number(a.date.slice(0, 2));
        const dateB = Number(b.date.slice(0, 2));
        return dateA - dateB;
    });

    return (
        <div>
            <table className='w-full table text-sm'>
                <thead className='font-bold'>
                    <tr>
                        <th className=''>дата</th>
                        <th className=''>детали</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedDatas.map(elem => {
                            return (
                                elem.itog.map(item => {
                                    return (
                                        <tr key={item.date}>
                                            <td className=''>{elem.date}</td>
                                            <td>
                                                <NavLink to={`/detail-online-otchet/${elem._id}`} className='underline text-blue-600'>детали</NavLink>
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
