import React from 'react';

function ModalUpdate({ data, isOpen, onClose, curator }) {
    if (!isOpen) return null;

    const sortedData = [...data].sort((a, b) => b.orders - a.orders);


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
                onClick={onClose}
                className="fixed inset-0 bg-black/50 opacity-50 z-40"
            ></div>
            <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg relative lg:left-40 z-50 overflow-auto lg:w-[700px] w-[350px] h-[600px]">
                <h2 className="text-2xl  font-semibold mb-4">Дополнительные данные:</h2>
                <table className="lg:table w-full">
                    <thead>
                        <tr className="bg-gray-700 dark:bg-black/80 dark:text-white text-white lg:text-black text-[10px]">
                            <th className="py-2 px-4 font-semibold text-left">Имя</th>
                            <th className="py-2 px-4 font-semibold text-left">Статус</th>
                            <th className="py-2 px-4 font-semibold text-left">кол-во заказов</th>
                            <th className="py-2 px-4 font-semibold text-left">комиссия</th>
                        </tr>
                    </thead>
                    <tbody className='lg:text-[12px] text-[9px]'>
                        {
                            sortedData.map(item => {
                                return (
                                    !curator.includes(item.name) && (
                                        <tr className=''>
                                            <td className='p-2'>{item.name}</td>
                                            <td className={item.status === "2" ? 'text-green-500' : 'text-red-500'}>
                                                {item.status === "2" ? 'активный' : 'неактивный'}
                                            </td>
                                            <td className='text-center p-2'>{item.orders}</td>
                                            <td>{item.summa}</td>
                                        </tr>
                                    )
                                );
                            })
                        }


                    </tbody>
                </table>
                <button
                    onClick={onClose}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mt-6 inline-flex items-center"
                >
                    Закрыть
                </button>
            </div>
        </div >
    );
}

export default ModalUpdate;
