import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const simCardLogistSlice = createSlice({
    name: 'simDataslog',
    initialState: {
        dataSim: [],
        loadingSim: false,
        errorSim: null,
    },
    reducers: {
        fetchDataRequest: (state) => {
            state.loadingItog = true;
            state.errorSim = null;
        },
        fetchDataSuccess: (state, action) => {
            state.dataSim = action.payload;
            state.loadingSim = false;
            state.errorSim = null;
        },
        fetchDataFailure: (state, action) => {
            state.loadingSim = false;
            state.errorSim = action.payload;
        },
    },
});

export const { fetchDataRequest, fetchDataSuccess, fetchDataFailure } = simCardLogistSlice.actions;

export const fetchSimCardLogist = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchDataRequest());

            const [responseLider, responseMonaco, responseTuran, responseFenix, responseFbox, responseliberty] = await Promise.all([
                api.get('/test/simcardliderlogs'),
                api.get('/test/simcardmonacologs'),
                api.get('/test/simcardturanlogs'),
                api.get('/test/simcardfenixlogs'),
                api.get('/test/simcardnewotdellogs'),
                api.get('/test/simcardlibertylogs')
            ]);

            const combinedData = {
                lider: responseLider.data,
                monaco: responseMonaco.data,
                turan: responseTuran.data,
                fenix: responseFenix.data,
                fbox: responseFbox.data,
                liberty: responseliberty.data
            };

            dispatch(fetchDataSuccess(combinedData));
        } catch (error) {
            dispatch(fetchDataFailure(error.message));
        }
    };
};

export default simCardLogistSlice.reducer;
