import React from 'react'
import TitleCard from '../../../components/Cards/TitleCard'

export default function AdminLogist({ select, data }) {
    return (
        <TitleCard title={`За текущий месяц ${select}`}>
            <table className='lg:table w-full lg:text-sm text-[8px]'>
                <thead>
                    <tr>
                        <td className='border lg:text-left text-center'>Имя</td>
                        <td className='border lg:text-left text-center'>Кол-во заказов</td>
                        <td className='border lg:text-left text-center'>Сумма</td>
                        <td className='border lg:text-left text-center'>коэфф</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.map(elem => {
                            return (
                                <tr>
                                    <td className='border p-1'>{elem.name}</td>
                                    <td className='border p-1 lg:text-left text-center'>{elem.order}</td>
                                    <td className='border p-1 lg:text-left text-center'>{elem.sum}</td>
                                    <td className='border p-1 lg:text-left text-center'>{parseFloat(elem.coeff).toFixed(2)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </TitleCard>
    )
}
