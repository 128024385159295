import React from 'react'
import ListManager from '../../../components/list-otchet/list-manager'
import { Navigate } from 'react-router-dom'
import { roles_leader } from '../../../constants/roles/LEADER_ROLES';

export default function ListManagerLeader({ datas }) {

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if ((roles && hasAccess(roles.roles.role, roles_leader))
        || roles.roles.role.includes('СМ ЛДР') || roles.roles.role.includes('ВМ ЛДР')) {
        return (
            <div>
                <ListManager datas={datas} api='leader' />
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}